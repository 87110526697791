@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;700;800;900&family=Nanum+Myeongjo&display=swap");

body {
  @apply bg-[#faf6eb] font-body text-[#33302a];
}
.container-lg {
  @apply max-w-screen-lg mx-auto px-5 py-10;
}

h1 {
  @apply text-2xl font-semibold text-primary;
}

h2 {
  @apply text-xl font-bold text-[#663e2f];
}

h4 {
  @apply text-lg font-bold leading-tight;
}

a {
  @apply text-primary font-semibold;
}
a:hover {
  @apply text-secondary;
}

.card p {
  @apply text-sm leading-loose;
}
